// Generated by Framer (e4277c8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/ZYAi1i0lo";

const enabledGestures = {TeuHkqynZ: {hover: true}};

const serializationHash = "framer-OPaXV"

const variantClassNames = {TeuHkqynZ: "framer-v-1liw7o3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, newTab, width, ...props}) => { return {...props, ej1AcaETc: newTab ?? props.ej1AcaETc, GUC0caw1V: link ?? props.GUC0caw1V} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GUC0caw1V, ej1AcaETc, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "TeuHkqynZ", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={GUC0caw1V} openInNewTab={ej1AcaETc}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1liw7o3", className, classNames)} framer-4wt0jy`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"TeuHkqynZ"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"TeuHkqynZ-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1w2vyry"} data-styles-preset={"ZYAi1i0lo"}>See Examples</motion.p></React.Fragment>} className={"framer-ceem4i"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"rWYsUcoid"} style={{"--framer-paragraph-spacing": "0px"}} variants={{"TeuHkqynZ-hover": {"--extracted-r6o4lv": "var(--token-3f16b744-2280-44d2-b9d1-7bb10e3a66bc, rgb(47, 131, 222))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"TeuHkqynZ-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-1w2vyry"} data-styles-preset={"ZYAi1i0lo"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-3f16b744-2280-44d2-b9d1-7bb10e3a66bc, rgb(47, 131, 222)))"}}>See Examples</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OPaXV.framer-4wt0jy, .framer-OPaXV .framer-4wt0jy { display: block; }", ".framer-OPaXV.framer-1liw7o3 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 8px; position: relative; text-decoration: none; width: min-content; }", ".framer-OPaXV .framer-ceem4i { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-OPaXV.framer-1liw7o3 { gap: 0px; } .framer-OPaXV.framer-1liw7o3 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-OPaXV.framer-1liw7o3 > :first-child { margin-left: 0px; } .framer-OPaXV.framer-1liw7o3 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 110
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"MOLxANCmy":{"layout":["auto","auto"]}}}
 * @framerVariables {"GUC0caw1V":"link","ej1AcaETc":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerqtr8xo0fP: React.ComponentType<Props> = withCSS(Component, css, "framer-OPaXV") as typeof Component;
export default Framerqtr8xo0fP;

Framerqtr8xo0fP.displayName = "Nav See Examples Link";

Framerqtr8xo0fP.defaultProps = {height: 48, width: 110};

addPropertyControls(Framerqtr8xo0fP, {GUC0caw1V: {title: "Link", type: ControlType.Link}, ej1AcaETc: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(Framerqtr8xo0fP, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})